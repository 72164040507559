/* You can add global styles to this file, and also import other style files */
@import '~fullcalendar/dist/fullcalendar.min.css';

body {
  margin: 0 0 0 0;
  font-family: 'Source Sans Pro' !important;
  background-color: #ffffff !important;
}

.mat-dialog-container {
  max-width: 90vw !important;
  min-width: 80vw !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
